import styled from 'styled-components';
import { styles, device } from '../styles/globals';

export const DocumentTitle = styled.h1`
  font-size: 48px;
  line-height: 1;
  font-family: Utopia Std;
  font-weight: 400;
  margin-bottom: 21px;

  @media ${device.tablet} {
    font-size: 38px;
    margin-bottom: 28px;
  }

  @media ${device.mobileL} {
    font-size: 29px;
    margin-bottom: 36px;
  }
`;

export const SubTitle = styled.h2`
  font-size: 29px;
  line-height: 1;
  font-family: Utopia Std;
  font-weight: 400;
  margin: 27px 0 21px;
  scroll-margin-top: 120px;
  scroll-snap-margin-top: 120px;

  @media ${device.tablet} {
    font-size: 26px;
    margin-top: 21px;
    scroll-margin-top: 90px;
    scroll-snap-margin-top: 90px;
  }

  @media ${device.mobileL} {
    font-size: 24px;
    margin-top: 15px;
    scroll-margin-top: 60px;
    scroll-snap-margin-top: 60px;
  }
`;

export const Paragraph = styled.p`
  font-size: 18px;
  padding-top: ${props => props.withMargin ? '27px' : '0'};
  padding-left: ${(props) => props.isList ? '24px' : '0'};
  &:not(:last-child) {
    padding-bottom: 21px;
  }

  @media ${device.tablet} {
    font-size: 16px;
    padding-top: ${props => props.withMargin ? '10px' : '0'};
  }

  @media ${device.mobileL} {
    font-size: 14px;
    padding-top: 0;
  }
`;

export const ParagraphLi = styled.li`
  padding-bottom: 8px;
`;

export const SubParagraphLi = styled(ParagraphLi)`
  position: relative;
  padding-left: 30px; 
  &:before {
    content: "(" counter(item) ") ";
    counter-increment: item;
    position: absolute;
    left: 0;
  }
`;

export const LegalEmailLink = styled.a`
  color: ${styles.baseFontColor};
  font-weight: bold;
  word-wrap: break-word;
`;
