import styled from 'styled-components';
import { colors, device } from '../../styles/globals';

const MainWrap = styled.div`
  height: 100%;
  background-color: ${colors.grey1};
  padding: 96px 260px;

  @media ${device.desktopS} {
    padding: 84px 10%;
  }

  @media ${device.tablet} {
    padding: 72px 55px;  
  }

  @media ${device.mobileL} {
    padding: 72px 35px;
`;

export default MainWrap;
