/* eslint-disable react/no-unescaped-entities, max-len */

import React from 'react';
import { Helmet } from 'react-helmet';
import DefaultLayout from '../containers/default';
import MainWrap from '../components/layout/wrap';
import {
  DocumentTitle,
  Paragraph,
  LegalEmailLink,
} from '../components/legal-formatting';

export default () => (
  <DefaultLayout noLogin>
    <Helmet>
      <title>all.health Contact Us</title>
      <meta name="description" content="all.health Contact Us" />
    </Helmet>
    <MainWrap>
      <DocumentTitle>contact us</DocumentTitle>
      <Paragraph>
        For general enquiries, please contact:
        {' '}
        <LegalEmailLink href="mailto:support&#064;all.health">
          support&#064;all.health
        </LegalEmailLink>
      </Paragraph>
      <Paragraph>
        For press enquires, please contact:
        {' '}
        <LegalEmailLink href="mailto:press&#064;all.health">
          press&#064;all.health
        </LegalEmailLink>
      </Paragraph>
    </MainWrap>
  </DefaultLayout>
);
